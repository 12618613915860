export const MANUFACTURER_LIST = "MANUFACTURER_LIST";
export const MANUFACTURER_LIST_REQUESTED_SUCCESSFUL =
  "MANUFACTURER_LIST_REQUESTED_SUCCESSFUL";
export const MANUFACTURER_LIST_REQUESTED_FAIL =
  "MANUFACTURER_LIST_REQUESTED_FAIL";

export const MAKE_LIST = "MAKE_LIST";
export const MAKE_LIST_REQUESTED_SUCCESSFUL = "MAKE_LIST_REQUESTED_SUCCESSFUL";
export const MAKE_LIST_REQUESTED_FAIL = "MAKE_LIST_REQUESTED_FAIL";

export const MODEL_LIST = "MODEL_LIST";
export const MODEL_LIST_REQUESTED_SUCCESSFUL =
  "MODEL_LIST_REQUESTED_SUCCESSFUL";
export const MODEL_LIST_REQUESTED_FAIL = "MODEL_LIST_REQUESTED_FAIL";

export const YEAR_LIST = "YEAR_LIST";
export const YEAR_LIST_REQUESTED_SUCCESSFUL = "YEAR_LIST_REQUESTED_SUCCESSFUL";
export const YEAR_LIST_REQUESTED_FAIL = "YEAR_LIST_REQUESTED_FAIL";
