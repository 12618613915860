export const USER_LOGOUT = "user/USER_LOGOUT";
export const USER_REFRESH = "user/USER_REFRESH";

export const USER_SIGNUP_REQUESTED = "user/USER_SIGNUP_REQUESTED";
export const USER_SIGNUP_SUCCESSFUL = "user/USER_SIGNUP_SUCCESSFUL";
export const USER_SIGNUP_FAIL = "user/USER_SIGNUP_FAIL";

export const USER_VERIFY_REQUESTED = "user/USER_VERIFY_REQUESTED";
export const USER_VERIFY_SUCCESSFUL = "user/USER_VERIFY_SUCCESSFUL";
export const USER_VERIFY_FAIL = "user/USER_VERIFY_FAIL";

export const USER_REFRESH_TOKEN_REQUESTED = "user/USER_REFRESH_TOKEN_REQUESTED";
export const USER_REFRESH_TOKEN_SUCCESSFUL =
  "user/USER_REFRESH_TOKEN_SUCCESSFUL";
export const USER_REFRESH_TOKEN_FAIL = "user/USER_REFRESH_TOKEN_FAIL";

export const USER_RESET_PASSWORD_REQUESTED =
  "user/USER_RESET_PASSWORD_REQUESTED";
export const USER_RESET_PASSWORD_SUCCESSFUL =
  "user/USER_RESET_PASSWORD_SUCCESSFUL";
export const USER_RESET_PASSWORD_FAIL = "user/USER_RESET_PASSWORD_FAIL";

export const USER_UPDATE_PASSWORD_REQUESTED =
  "user/USER_UPDATE_PASSWORD_REQUESTED";
export const USER_UPDATE_PASSWORD_SUCCESSFUL =
  "user/USER_UPDATE_PASSWORD_SUCCESSFUL";
export const USER_UPDATE_PASSWORD_FAIL = "user/USER_UPDATE_PASSWORD_FAIL";

export const USER_AUTHENTICATE_REQUESTED = "user/USER_AUTHENTICATE_REQUESTED";
export const USER_AUTHENTICATE_SUCCESSFUL = "user/USER_AUTHENTICATE_SUCCESSFUL";
export const USER_AUTHENTICATE_FAIL = "user/USER_AUTHENTICATE_FAIL";

export const USER_SUBSCRIBE_REQUESTED = "user/USER_SUBSCRIBE_REQUESTED";
export const USER_SUBSCRIBE_SUCCESSFUL = "user/USER_SUBSCRIBE_SUCCESSFUL";
export const USER_SUBSCRIBE_FAIL = "user/USER_SUBSCRIBE_FAIL";

export const USER_SUBSCRIBE_REQUESTED_MONTHLY =
  "user/USER_SUBSCRIBE_REQUESTED_MONTHLY";
export const USER_SUBSCRIBE_SUCCESSFUL_MONTHLY =
  "user/USER_SUBSCRIBE_SUCCESSFUL_MONTHLY";
export const USER_SUBSCRIBE_FAIL_MONTHLY = "user/USER_SUBSCRIBE_FAIL_MONTHLY";

export const USER_PROFILE_REQUESTED = "user/USER_PROFILE_REQUESTED";
export const USER_PROFILE_SUCCESSFUL = "user/USER_PROFILE_SUCCESSFUL";
export const USER_PROFILE_FAIL = "user/USER_PROFILE_FAIL";

export const USER_SIGNUP_COFIRMATION = "user/USER_SIGNUP_COFIRMATION";
export const USER_RESET_SIGNUP_COFIRMATION =
  "user/USER_RESET_SIGNUP_COFIRMATION";
export const USER_REDIRECT_HOMEPAGE = "user/USER_REDIRECT_HOMEPAGE";

export const USER_ERROR_MESSAGE_EMPTY = "user/USER_ERROR_MESSAGE_EMPTY"
