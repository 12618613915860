import { convertToServerTimeZone } from '../../utils/format';

export const configData = (params) => {
  const detail = {
    ads: params.ads,
    article_publish_date: convertToServerTimeZone(params.article_publish_date),
    article_received_date: convertToServerTimeZone(params.article_received_date),
    assets: params.assets,
    available_in_trends: params.available_in_trends,
    body: params.body,
    byline: params.byline,
    byline_link: params.byline_link,
    campaigns: params.campaigns,
    canonical_link: params.canonical_link,
    city: params.city,
    content_id: params.content_id,
    content_partner: params.content_partner,
    country: params.country,
    disable_personalization: params.disable_personalization,
    guid: params.guid,
    headline: params.headline,
    homepage_availability: params.homepage_availability,
    id: params.id,
    include_in_search: params.include_in_search,
    is_featured: params.is_featured,
    is_promoted_content: params.is_promoted_content,
    is_timely_content: params.is_timely_content,
    makes: params.makes,
    manufacturers: params.manufacturers,
    models: params.models,
    partner: params.partner,
    preview_path: params.preview_path,
    publishing_state: params.publishing_state,
    related_articles: params.related_articles,
    search_boost: params.search_boost,
    search_keywords: params.search_keywords,
    secondary_navigation: params.secondary_navigation,
    seo_keywords: params.seo_keywords,
    seo_meta_description: params.seo_meta_description,
    seo_meta_name: params.seo_meta_name,
    sponsor: params.sponsor,
    state: params.state,
    subheading: params.subheading,
    synopsis: params.synopsis,
    tag: params.tag,
    template: params.template,
    template_configuration: params.template_configuration,
    url: params.url,
    years: params.years
  };
  return detail;
};