export const PARTNER_LIST = 'PARTNER_LIST'
export const PARTNER_LIST_REQUESTED_SUCCESSFUL = 'PARTNER_LIST_REQUESTED_SUCCESSFUL'
export const PARTNER_LIST_REQUESTED_FAIL = 'PARTNER_LIST_REQUESTED_FAIL'

export const TEMPLATE_LIST = 'TEMPLATE_LIST'
export const TEMPLATE_LIST_REQUESTED_SUCCESSFUL = 'TEMPLATE_LIST_REQUESTED_SUCCESSFUL'
export const TEMPLATE_LIST_REQUESTED_FAIL = 'TEMPLATE_LIST_REQUESTED_FAIL'

export const ASSET_LIST = 'ASSET_LIST'
export const ASSET_LIST_REQUESTED_SUCCESSFUL = 'ASSET_LIST_REQUESTED_SUCCESSFUL'
export const ASSET_LIST_REQUESTED_FAIL = 'ASSET_LIST_REQUESTED_FAIL'

export const TEMPLATE_LOCATION_LIST = 'TEMPLATE_LOCATION_LIST'
export const TEMPLATE_LOCATION_LIST_REQUESTED_SUCCESSFUL = 'TEMPLATE_LOCATION_LIST_REQUESTED_SUCCESSFUL'
export const TEMPLATE_LOCATION_LIST_REQUESTED_FAIL = 'TEMPLATE_LOCATION_LIST_REQUESTED_FAIL'