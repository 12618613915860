export const COUNTRY_LIST = 'COUNTRY_LIST'
export const COUNTRY_LIST_REQUESTED_SUCCESSFUL = 'COUNTRY_LIST_REQUESTED_SUCCESSFUL'
export const COUNTRY_LIST_REQUESTED_FAIL = 'COUNTRY_LIST_REQUESTED_FAIL'

export const STATE_LIST = 'STATE_LIST'
export const STATE_LIST_REQUESTED_SUCCESSFUL = 'STATE_LIST_REQUESTED_SUCCESSFUL'
export const STATE_LIST_REQUESTED_FAIL = 'STATE_LIST_REQUESTED_FAIL'


export const CITY_LIST = 'CITY_LIST'
export const CITY_LIST_REQUESTED_SUCCESSFUL = 'CITY_LIST_REQUESTED_SUCCESSFUL'
export const CITY_LIST_REQUESTED_FAIL = 'CITY_LIST_REQUESTED_FAIL'

export const SPONSOR_LIST = 'SPONSOR_LIST'
export const SPONSOR_LIST_REQUESTED_SUCCESSFUL = 'SPONSOR_LIST_REQUESTED_SUCCESSFUL'
export const SPONSOR_LIST_REQUESTED_FAIL = 'SPONSOR_LIST_REQUESTED_FAIL'

export const CAMPAIGN_LIST = 'CAMPAIGN_LIST'
export const CAMPAIGN_LIST_REQUESTED_SUCCESSFUL = 'CAMPAIGN_LIST_REQUESTED_SUCCESSFUL'
export const CAMPAIGN_LIST_REQUESTED_FAIL = 'CAMPAIGN_LIST_REQUESTED_FAIL'
