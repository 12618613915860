export const UPDATE_PROFILE_REQUEST = "profile/UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESSFUL = "profile/UPDATE_PROFILE_SUCCESSFUL";
export const UPDATE_PROFILE_FAIL = "profile/UPDATE_PROFILE_FAIL";

export const UPDATE_SUBJECT_INTEREST_REQUEST =
  "profile/UPDATE_SUBJECT_INTEREST_REQUEST";
export const UPDATE_SUBJECT_INTEREST_SUCCESSFUL =
  "profile/UPDATE_SUBJECT_INTEREST_SUCCESSFUL";
export const UPDATE_SUBJECT_INTEREST_FAIL =
  "profile/UPDATE_SUBJECT_INTEREST_FAIL";

export const UPDATE_BRAND_REQUEST = "profile/UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESSFUL = "profile/UPDATE_BRAND_SUCCESSFUL";
export const UPDATE_BRAND_FAIL = "profile/UPDATE_BRAND_FAIL";

export const GET_USER_INFO_REQUEST = "profile/GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESSFUL = "profile/GET_USER_INFO_SUCCESSFUL";
export const GET_USER_INFO_FAIL = "profile/GET_USER_INFO_FAIL";

export const GET_OLD_PASSWORD_REQUEST = "profile/GET_OLD_PASSWORD_REQUEST";
export const GET_OLD_PASSWORD_SUCCESSFUL =
  "profile/GET_OLD_PASSWORD_SUCCESSFUL";
export const GET_OLD_PASSWORD_FAIL = "profile/GET_OLD_PASSWORD_FAIL";

export const POST_DELETE_CAR_REQUEST = "profile/POST_DELETE_CAR_REQUEST";
export const POST_DELETE_CAR_SUCCESSFUL = "profile/POST_DELETE_CAR_SUCCESSFUL";
export const POST_DELETE_CAR_FAIL = "profile/POST_DELETE_CAR_FAIL";

export const POST_DELETE_CAR_LIST_REQUEST =
  "profile/POST_DELETE_CAR_LIST_REQUEST";
export const POST_DELETE_CAR_LIST_SUCCESSFUL =
  "profile/POST_DELETE_CAR_LIST_SUCCESSFUL";
export const POST_DELETE_CAR_LIST_FAIL = "profile/POST_DELETE_CAR_LIST_FAIL";

export const POST_IMAGE_UPLOAD_REQUEST = "profile/POST_IMAGE_UPLOAD_REQUEST";
export const POST_IMAGE_UPLOAD_SUCCESSFUL =
  "profile/POST_IMAGE_UPLOAD_SUCCESSFUL";
export const POST_IMAGE_UPLOAD_FAIL = "profile/POST_IMAGE_UPLOAD_FAIL";
