export const GET_INSURANCE_QUOTE_REQUESTED =
  "question/GET_INSURANCE_QUOTE_REQUESTED";

export const GET_YEAR_LIST = "question/GET_YEAR_LIST";

export const GET_MAKE_LIST = "question/GET_MAKE_LIST";

export const GET_MODEL_LIST_REQUESTED = "question/GET_MODEL_LIST";

export const GET_INSURANCE_QUOTE_SUCCESSFUL =
  "question/GET_INSURANCE_QUOTE_SUCCESSFUL";

export const GET_INSURANCE_QUOTE_FAIL = "question/GET_INSURANCE_QUOTE_FAIL";

export const GET_MODEL_LIST_SUCCESSFUL = "question/GET_MODEL_LIST_SUCCESSFUL";
export const GET_MODEL_LIST_FAIL = "question/GET_MODEL_LIST_FAIL";
export const GET_YEAR_LIST_SUCCESSFUL = "question/GET_YEAR_LIST_SUCCESSFUL";
export const GET_YEAR_LIST_FAIL = "question/GET_YEAR_LIST_FAIL";
export const GET_YEAR_LIST_REQUESTED = "question/GET_YEAR_LIST_REQUESTED";
export const GET_MAKE_LIST_SUCCESSFUL = "question/GET_MAKE_LIST_SUCCESSFUL";
export const GET_MAKE_LIST_FAIL = "question/GET_MAKE_LIST_FAIL";
export const GET_MAKE_LIST_REQUESTED = "question/GET_MAKE_LIST_REQUESTED";
