export const GET_CMS_PAGE_REQUEST = 'page/GET_CMS_PAGE_REQUEST'
export const GET_CMS_PAGE_SUCCESSFUL = 'page/GET_CMS_PAGE_SUCCESSFUL'
export const GET_CMS_PAGE_FAIL = 'page/GET_CMS_PAGE_FAIL'
export const GET_CONTENT_GET_REQUEST = 'page/GET_CONTENT_GET_REQUEST'
export const GET_CONTENT_GET_SUCCESSFUL = 'page/GET_CONTENT_GET_SUCCESSFUL'
export const GET_CONTENT_GET_FAIL = 'page/GET_CONTENT_GET_FAIL'
export const GET_Publish_Article_REQUEST = 'page/GET_Publish_Article_REQUEST'
export const GET_Publish_Article_SUCCESSFUL = 'page/GET_Publish_Article_SUCCESSFUL'
export const GET_Publish_Article_FAIL = 'page/GET_Publish_Article_FAIL'
export const POST_CMS_PAGE_TAG_REQUEST = 'page/POST_CMS_PAGE_TAG_REQUEST'
export const POST_CMS_PAGE_TAG_SUCCESSFUL = 'page/POST_CMS_PAGE_TAG_SUCCESSFUL'
export const POST_CMS_PAGE_TAG_FAIL = 'page/POST_CMS_PAGE_TAG_FAIL'
export const POST_CMS_PAGE_TAG_BY_ID_REQUEST = 'page/POST_CMS_PAGE_TAG_BY_ID_REQUEST'
export const POST_CMS_PAGE_TAG_BY_ID_SUCCESSFUL = 'page/POST_CMS_PAGE_TAG_BY_ID_SUCCESSFUL'
export const POST_CMS_PAGE_TAG_BY_ID_FAIL = 'page/POST_CMS_PAGE_TAG_BY_ID_FAIL'
export const POST_CMS_PAGE_PUBLISH_SETTINGS_REQUEST = 'page/POST_CMS_PAGE_PUBLISH_SETTINGS_REQUEST'
export const POST_CMS_PAGE_PUBLISH_SETTINGS_SUCCESSFUL = 'page/POST_CMS_PAGE_PUBLISH_SETTINGS_SUCCESSFUL'
export const POST_CMS_PAGE_PUBLISH_SETTINGS_FAIL = 'page/POST_CMS_PAGE_PUBLISH_SETTINGS_FAIL'
export const POST_CMS_PAGE_CONTENT_SECTION_REQUEST = 'pagePOST_CMS_PAGE_CONTENT_SECTION_REQUEST'
export const POST_CMS_PAGE_CONTENT_SECTION_SUCCESSFUL = 'page/POST_CMS_PAGE_CONTENT_SECTION_SUCCESSFUL'
export const POST_CMS_PAGE_CONTENT_SECTION_FAIL = 'page/POST_CMS_PAGE_CONTENT_SECTION_FAIL'
export const GET_CONTENT_OPTIONS_SUCCESSFUL = 'page/GET_CONTENT_OPTIONS_SUCCESSFUL'
export const GET_CONTENT_OPTIONS_FAIL = 'page/GET_CONTENT_OPTIONS_FAIL'
export const GET_CONTENT_OPTIONS_REQUEST= 'page/GET_CONTENT_OPTIONS_REQUEST'
export const ADD_CAMPAIGN_LIST = 'page/ADD_CAMPAIGN_LIST'
export const POST_AD_FAIL = 'page/POST_AD_FAIL'
export const POST_AD_REQUEST= 'page/POST_AD_REQUEST'
export const POST_AD_SUCCESSFULL = 'page/POST_AD_SUCCESSFULL'
export const POST_SPONSOR_FAIL = 'page/POST_SPONSOR_FAIL'
export const POST_SPONSOR_REQUEST= 'page/POST_SPONSOR_REQUEST'
export const POST_SPONSOR_SUCCESSFULL = 'page/POST_SPONSOR_SUCCESSFULL'
