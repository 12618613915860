import React, {Component} from 'react';

export class SoftRegistration extends Component {

    render() {

        return (
            <div className="main-subheader-content">
                The more we get to know each other the more exciting your AutoLife
            </div>
        )
    }
}

