export const GET_CAR_WORTH_YEAR = "carWorth/GET_CAR_WORTH_YEAR";

export const GET_CAR_WORTH_MAKE = "carWorth/GET_CAR_WORTH_MAKE";

export const GET_CAR_WORTH_MODEL = "carWorth/GET_CAR_WORTH_MODEL";

export const GET_CAR_WORTH_TRIM = "carWorth/GET_CAR_WORTH_TRIM";

export const GET_CAR_WORTH_STYLE = "carWorth/GET_CAR_WORTH_STYLE";

export const GET_CAR_WORTH_AVG_PRICE_YEAR =
  "carWorth/GET_CAR_WORTH_AVG_PRICE_YEAR";

export const GET_CAR_WORTH_AVG_PRICE_MAKE_REQUEST = "carWorth/GET_CAR_WORTH_AVG_PRICE_MAKE_REQUEST";
export const GET_CAR_WORTH_AVG_PRICE_MAKE =
  "carWorth/GET_CAR_WORTH_AVG_PRICE_MAKE";

export const GET_CAR_WORTH_AVG_PRICE_MODEL_REQUEST = "carWorth/GET_CAR_WORTH_AVG_PRICE_MODEL_REQUEST";
export const GET_CAR_WORTH_AVG_PRICE_MODEL =
  "carWorth/GET_CAR_WORTH_AVG_PRICE_MODEL";

export const GET_CAR_WORTH_AVG_PRICE_TRIM =
  "carWorth/GET_CAR_WORTH_AVG_PRICE_TRIM";

export const GET_CAR_WORTH_AVG_PRICE_STYLE =
  "carWorth/GET_CAR_WORTH_AVG_PRICE_STYLE";

export const GET_CAR_WORTH_FUTURE_VALUE_YEAR =
  "carWorth/GET_CAR_WORTH_FUTURE_VALUE_YEAR";

export const GET_CAR_WORTH_FUTURE_VALUE_MAKE =
  "carWorth/GET_CAR_WORTH_FUTURE_VALUE";

export const GET_CAR_WORTH_FUTURE_VALUE_MODEL =
  "carWorth/GET_CAR_WORTH_FUTURE_VALUE_MODEL";

export const GET_CAR_WORTH_FUTURE_VALUE_TRIM =
  "carWorth/GET_CAR_WORTH_FUTURE_VALUE_TRIM";

export const GET_CAR_WORTH_FUTURE_VALUE_STYLE =
  "carWorth/GET_CAR_WORTH_FUTURE_VALUE_STYLE";

export const TRADE_IN_VALUE_STEP_TWO = "carWorth/TRADE_IN_VALUE_STEP_TWO";

export const POST_AVG_PRICE_RESULT = "carWorth/POST_AVG_PRICE_RESULT";

export const POST_FUTURE_VALUE_RESULT = "carWorth/POST_FUTURE_VALUE_RESULT";
