export const TOGGLE_SCROLLING = 'TOGGLE_SCROLLING';

export const SET_PARALLAX = 'SET_PARALLAX';

export const CHANGE_HEADER_LAYOUT = 'app/CHANGE_HEADER_LAYOUT'
 
export const CHANGE_FOOTER_LAYOUT = 'app/CHANGE_FOOTER_LAYOUT'
 
export const CHANGE_HEADER_STEPPER = 'app/CHANGE_HEADER_STEPPER'
 
export const SHOW_HEADER_STEPPER = 'app/SHOW_HEADER_STEPPER'

export const SET_INITIAL_PAGE_LOADED = 'app/SET_INITIAL_PAGE_LOADED'
 
export const SET_HEADER_FOOTER_VISIBLE = 'app/SET_HEADER_FOOTER_VISIBLE'
 